import React from "react"

import Layout from "../components/layout"

const Ref = ({ pageContext }) => {
  const ref = pageContext.ref
  return (
    <Layout>
        <pre>{ref.text}</pre>
    </Layout>
  )
}

export default Ref
